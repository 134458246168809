import { graphql } from 'gatsby';

import List from './produits-default';

export default List;

export const query = graphql`
  query ProduitsList($isOnSale: Boolean!, $offset: Int!, $limit: Int!) {
    categories: allCategory(
      filter: {
        parent: { id: { eq: null } }
        hidden: { eq: false }
        deleted: { eq: false }
      }
      sort: { fields: datetime, order: DESC }
    ) {
      edges {
        node {
          id
          libelle
          traductions {
            en {
              libelle
            }
          }
        }
      }
    }
    items: allProduit(
      filter: { hidden: { eq: false }, isOnSale: { eq: $isOnSale } }
      sort: { fields: datetime, order: DESC }
      limit: $limit
      skip: $offset
    ) {
      totalCount
      edges {
        node {
          hidden
          id
          isOnSale
          kind
          medias {
            id
            origin
            pathname
            height
            width
          }
          name
          pathname
          price
          traductions {
            en {
              name
            }
          }
        }
      }
    }
  }
`;
